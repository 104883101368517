import { Button } from '@mui/material'
import { ArrowRightIcon } from '@mui/x-date-pickers'
import { Project } from 'generated/graphql'
import { useTheme } from 'hooks/theme.hook'
import { FaTasks } from 'react-icons/fa'
import { GoProjectTemplate } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import { themeColor } from 'utils/theme'
import './ProjectInfo.styles.scss'
function colorBasedOnProject(projectName: string) {
  // Hash the project name to generate consistent RGB values
  let hash = 0
  for (let i = 0; i < projectName.length; i++) {
    hash = projectName.charCodeAt(i) + ((hash << 5) - hash)
  }
  const r = (hash >> 16) & 0xff
  const g = (hash >> 8) & 0xff
  const b = hash & 0xff

  // Ensure values are within a light and aesthetic range
  return `rgb(${(r % 200) + 55}, ${(g % 200) + 55}, ${(b % 200) + 55})`
}
function colorBasedOnTask(taskName: string) {
  // Hash the project name to generate consistent RGB values
  let hash = 0
  for (let i = 0; i < taskName.length; i++) {
    hash = taskName.charCodeAt(i) + ((hash << 5) - hash)
  }
  const r = (hash >> 16) & 0xff
  const g = (hash >> 8) & 0xff
  const b = hash & 0xff

  // Ensure values are within a light and aesthetic range
  return `rgb(${(r % 250) + 55}, ${(g % 150) + 55}, ${(b % 1) + 55})`
}
const ProjectInfo = ({
  project,
  currentDate,
  searchParams,
}: {
  project: Project
  currentDate: Date
  searchParams: URLSearchParams
}) => {
  const themeObj = useTheme()
  const navigate = useNavigate()
  return (
    <div className="project-info">
      <div style={{ width: '100%' }}>
        <div
          className="project-info-wrapper__labels"
          style={{ color: themeObj?.theme == 'dark' ? 'white' : '#282828' }}
        >
          <FaTasks className="icons" style={{ marginRight: '10px' }} />
          <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
            Sub projects:
          </span>
        </div>
        <ul>
          {project.subProjects?.flatMap((subProject, i) => {
            const color = colorBasedOnProject(subProject?.name || 'default')
            return (
              <li key={i} style={{ position: 'relative' }}>
                <Button
                  className="button"
                  color="inherit"
                  sx={{
                    backgroundColor: 'transparent',
                    border: `1px solid ${themeColor}`,
                    overflow: 'hidden',
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboard/project/${project.name}?subProject=${subProject?.name}&history=Projekt`
                    )
                  }
                  style={{ margin: '5px' }}
                  variant="contained"
                  endIcon={<ArrowRightIcon />}
                >
                  {' '}
                  <span
                    style={{
                      position: 'absolute',
                      left: '0%',
                      top: '50%',
                      width: '5px',
                      height: '100%',
                      backgroundColor: `${color}`,
                      overflow: 'visible',
                      minWidth: '2px',
                      minHeight: '2px',
                      transform: 'translate(0%,-50%)',
                    }}
                  ></span>
                  {subProject?.name}
                </Button>
              </li>
            )
          })}
        </ul>
      </div>
      <div>
        <div
          className="project-info-wrapper__labels"
          style={{ color: themeObj?.theme == 'dark' ? 'white' : '#282828' }}
        >
          <GoProjectTemplate
            className="icons"
            style={{ marginRight: '10px' }}
          />
          <span style={{ fontWeight: 'bold', fontSize: '15px' }}>Tasks:</span>
        </div>
        <div>
          {project.subProjects?.flatMap((subProject, index) => {
            const projectColor = colorBasedOnProject(
              subProject?.name || 'default'
            )
            return subProject?.tasks?.map((task, i) => {
              const taskColor = colorBasedOnTask(task?.name || 'default')
              return subProject?.tasks && subProject?.tasks?.length > 0 ? (
                <Button
                  key={`${subProject.name}-${task?.name}`}
                  color="inherit"
                  className="button"
                  onClick={() =>
                    navigate(
                      `/dashboard/tasks/${task?.name}?project=${
                        project.name
                      }&subProject=${task?.project!.name}&history=Projekt`
                    )
                  }
                  sx={{
                    backgroundColor: 'transparent',
                    overflow: 'hidden',
                    border: `1px solid ${themeColor}`,
                  }}
                  style={{ margin: '5px' }}
                  variant="contained"
                  endIcon={<ArrowRightIcon />}
                >
                  {' '}
                  <span
                    style={{
                      position: 'absolute',
                      left: '0',
                      top: '50%',
                      width: '5px',
                      height: '100%',
                      backgroundColor: `${projectColor}`,
                      overflow: 'visible',
                      minWidth: '2px',
                      minHeight: '2px',
                      transform: 'translate(0%,-50%)',
                    }}
                  ></span>
                  <span
                    style={{
                      position: 'absolute',
                      right: '2px',
                      top: '5px',
                      width: '5px',
                      height: '5px',
                      backgroundColor: `${taskColor}`,
                      overflow: 'visible',
                      minWidth: '2px',
                      minHeight: '2px',
                      transform: 'translate(0%,-50%)',
                      borderRadius: '50%',
                    }}
                  ></span>
                  {task?.name}
                </Button>
              ) : (
                '_'
              )
            })
          })}
        </div>
      </div>

      <div>
        {' '}
        <div
          className="project-info-wrapper__labels"
          style={{ color: themeObj?.theme == 'dark' ? 'white' : '#282828' }}
        >
          <GoProjectTemplate
            className="icons"
            style={{ marginRight: '10px' }}
          />
          <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
            Sub tasks:
          </span>
        </div>
        <div>
          {project.subProjects?.flatMap((subProject) =>
            subProject?.tasks?.map((task, i) => {
              const taskColor = colorBasedOnTask(task?.name || 'default')
              if (task?.subTasks) {
                return task?.subTasks?.flatMap((subTask, j) => {
                  return (
                    <Button
                      color="inherit"
                      className="button"
                      sx={{
                        backgroundColor: 'transparent',
                        border: `1px solid ${themeColor}`,
                      }}
                      style={{ margin: '5px' }}
                      variant="contained"
                      endIcon={<ArrowRightIcon />}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          left: '2px',
                          top: '5px',
                          width: '5px',
                          height: '5px',
                          backgroundColor: `${taskColor}`,
                          overflow: 'visible',
                          minWidth: '5px',
                          minHeight: '5px',
                          transform: 'translate(0%,-50%)',
                          borderRadius: '50%',
                          zIndex: 20,
                        }}
                      ></span>
                      {subTask?.name}
                    </Button>
                  )
                })
              }
            })
          )}
        </div>
      </div>
    </div>
  )
}
export default ProjectInfo
